import { useEffect, useRef, useState } from 'react';
import { FaAngleDown } from 'react-icons/fa';
import CardSubMenu from 'src/components/cards/card-sub-menu';
import { useAppSelector } from 'src/redux/store';
import { SHOW_COMMEMORATE } from 'src/constants';
import {
  DATALAYER_TYPE,
  sendDataLayerGlobal
} from 'src/services/member-auth/datalayer/send-datalayer';
import { useRouter } from 'next/router';
function useOnClickOutside (ref, handler) {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    document.addEventListener('mouseleave', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
      document.removeEventListener('mouseleave', listener);
    };
  }, [ref, handler]);
}

const NavDesktop = () => {
  const router = useRouter();
  const navData = useAppSelector((props) => props.navData.result);
  const [subToggle, setSubToggle] = useState({
    status: false,
    key: null
  });

  let cloneNavData = [];
  let newNavData = [];
  if (navData !== null) {
    cloneNavData = [...navData];
    newNavData = cloneNavData.splice(0, 7);
  }
  const subBoxRef = useRef();

  useOnClickOutside(subBoxRef, async () => {
    await setSubToggle({
      status: false,
      key: null
    });
  });

  return (
    <>
      <nav
        className='nav-dropdown-desktop'
        style={{
          filter: SHOW_COMMEMORATE === 'true' ? 'grayscale(1)' : 'unset'
        }}
      >
        {/* ---------------------------------- MENU ---------------------------------- */}
        <ul className='nav-menu'>
          {Array.isArray(newNavData) &&
            newNavData.map((menu, index) => (
              <li
                key={index}
                className='active-menu'
                onMouseLeave={() => setSubToggle({ status: false, key: null })}
              >
                <a
                  onMouseOver={() => setSubToggle({ status: true, key: index })}
                  onFocus={() => {}}
                  href={`/${menu?.link}`}
                  title={menu?.nameTh}
                  //! DATALAYER
                  onClick={() =>
                    sendDataLayerGlobal({
                      type: DATALAYER_TYPE.TRACK_POSITION,
                      router: router?.pathname,
                      position: 'on:header',
                      section: 'header',
                      data: {
                        title: menu?.nameTh,
                        heading: menu?.nameTh
                      }
                    })
                  }
                >
                  <span> {menu?.nameTh} </span>
                  {menu?.sub.length > 0 && <FaAngleDown />}
                </a>
                {/* --------------------------------- SUBMENU -------------------------------- */}

                {subToggle?.key === index &&
                  subToggle?.status &&
                  menu?.sub.length > 0 && (
                    <ul
                      className={`nav-sub-menu${
                        [0, 1, 2].includes(index) ? ' --left' : ' --right'
                      }`}
                      ref={subBoxRef}
                      onMouseLeave={() =>
                        setSubToggle({ key: null, status: false })
                      }
                    >
                      <CardSubMenu data={menu.sub} menu={menu} />
                    </ul>
                )}
              </li>
            ))}
        </ul>
      </nav>
    </>
  );
};

export default NavDesktop;
